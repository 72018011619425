import { Box } from '@mui/material'
import React from 'react'
import logoImage from "../../images/eastwestLogo.svg"


const CustomerHeader = () => {
    return (
        <Box>
            <Box className="container" style={{ marginTop: "20px" }}>
                <Box>
                    <img src={logoImage} alt='' style={{ width: '159px', height: '98.5px', margin: '0 159.6px 28.6px 0', objectFit: 'contain' }} />
                </Box>
            </Box>
        </Box>
    )
}

export default CustomerHeader